import { ActionIcon, Avatar, Grid, Group, Space, Text } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconFilter, IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { BrandApi, CategoryApi, GoodsWarehouseApi, ItemTypeApi, StockApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { usePermission } from "../permission";
import { Segmented } from "../segmented";
import { SelectFilter } from "../select-filter";
import { Tag } from "../tags";

const filterInit = {
  variantStatus: "",
  supplierType: "",
  warehouseId: "",
  brandId: "",
  itemTypeId: "",
  classificationId: "",
  subClassificationId: "",
  categoryId: "",
  subCategoryId: "",
};

type IFilters = {
  variantStatus: string | null;
  supplierType: string | null;
  warehouseId: string | null;
  brandId: string | null;
  itemTypeId: string | null;
  classificationId: string | null;
  subClassificationId: string | null;
  categoryId: string | null;
  subCategoryId: string | null;
};

export const RemainderList = () => {
  const ERP_GDS = usePermission("ERP_GDS");
  const ERP_GDS_WRH_SET = usePermission("ERP_GDS_WRH_SET");

  const [filters, setFilters] = useState<IFilters>(filterInit);
  const { supplierTypes = [], stockStatus, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ stockStatus, variantPriceHistoryStatus });

  const [warehouseFilterDelay, setWarehouseFilterDelay] = useDebouncedState<any>({ query: "" }, 800);

  const { data: warehouseSelects = [] } = useSwr<any[]>(
    ERP_GDS_WRH_SET.hasAccess && `/erp/goods/warehouse/select?query=${JSON.stringify(warehouseFilterDelay)}`,
    async () =>
      ERP_GDS_WRH_SET.hasAccess &&
      (await GoodsWarehouseApi.select({
        query: warehouseFilterDelay.query || "",
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const [itemTypeFilterDelay, setItemTypeSelectsFilterDelay] = useDebouncedState<any>({ query: "", isGoods: true, isService: false }, 800);
  const { data: itemTypeSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/item_type/select?query=${JSON.stringify(itemTypeFilterDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await ItemTypeApi.select({
        ...itemTypeFilterDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const [classificationQueryDelay, setClassificationQueryDelay] = useDebouncedState<string>("", 800);
  const [classificationFilter, setClassificationFilter] = useState<any>({ query: "", type: "CLASSIFICATION", itemTypeId: null, parentId: "" });

  const { data: classificationSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...classificationFilter, query: classificationQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && classificationFilter.itemTypeId
        ? await CategoryApi.select({
            ...classificationFilter,
            query: classificationQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [subClassificationFilter, setSubClassificationFilter] = useState<any>({
    query: "",
    type: "SUB_CLASSIFICATION",
    itemTypeId: null,
    parentId: null,
  });
  const [subClassificationQueyDelay, setSubClassificationQueryDelay] = useDebouncedState<string>("", 800);
  const { data: subClassificationSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...subClassificationFilter, query: subClassificationQueyDelay })}`,
    async () =>
      ERP_GDS.isEdit && subClassificationFilter.itemTypeId && subClassificationFilter.parentId
        ? await CategoryApi.select({
            ...subClassificationFilter,
            query: subClassificationQueyDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [categoriesQueryDelay, setCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const [categoriesFilter, setCategoriesFilter] = useState<any>({ query: "", type: "CATEGORY", itemTypeId: null, parentId: "" });
  const { data: categoriesSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...categoriesFilter, query: categoriesQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && categoriesFilter.itemTypeId
        ? await CategoryApi.select({
            ...categoriesFilter,
            query: categoriesQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [subCategoriesQueryDelay, setSubCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const [subCategoriesFilter, setSubCategoriesFilter] = useState<any>({ query: "", type: "SUB_CATEGORY", itemTypeId: null, parentId: "" });
  const { data: subCategoriesSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...subCategoriesFilter, query: subCategoriesQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && categoriesFilter.itemTypeId
        ? await CategoryApi.select({
            ...subCategoriesFilter,
            query: subCategoriesQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [brandQueryDelay, setBrandQueryDelay] = useDebouncedState<string>("", 800);
  const { data: brandSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/brand/select?query=${JSON.stringify(brandQueryDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await BrandApi.select({
        query: brandQueryDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const onClearFilter = () => {
    setFilters(filterInit);
    setClassificationFilter({ query: null, type: "CLASSIFICATION", itemTypeId: null, parentId: null });
    setSubClassificationFilter({
      query: null,
      type: "SUB_CLASSIFICATION",
      itemTypeId: null,
      parentId: null,
    });
    setCategoriesFilter({ query: null, type: "CATEGORY", itemTypeId: null, parentId: null });
    setSubCategoriesFilter({ query: null, type: "SUB_CATEGORY", itemTypeId: null, parentId: null });
  };

  return (
    <>
      <Group style={{ overflowX: "auto" }} pb={"xs"}>
        <Segmented
          label="Барааны статус"
          value={filters?.variantStatus || ""}
          onChange={(e: any) => {
            setFilters({
              ...filters,
              variantStatus: e,
            });
          }}
          data={[
            {
              name: "Бүгд",
              code: "",
              color: "violet",
            },
            {
              name: "Идэвхтэй",
              code: "ACTIVE",
              color: "green",
            },
            {
              name: "Идэвхгүй",
              code: "INACTIVE",
              color: "violet",
            },
          ]}
        />
      </Group>
      <Space h={"xs"} />
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {}}
            label="Захиалгын төрөл"
            value={`${filters?.supplierType || ""}`}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                supplierType: e,
              });
            }}
            data={
              [
                {
                  name: "Бүгд",
                  code: "",
                  color: "",
                },
                ...supplierTypes,
              ]?.map((item: any) => {
                return {
                  name: item?.name || "",
                  code: item?.code || "",
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS_WRH_SET.hasAccess}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {
              setWarehouseFilterDelay({
                ...warehouseFilterDelay,
                query: e,
              });
            }}
            label="Агуулах"
            value={`${filters?.warehouseId || ""}`}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                warehouseId: e,
              });
            }}
            data={
              warehouseSelects?.map((item: any) => {
                return {
                  name: item?.name || "",
                  code: item?.id || "",
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => setBrandQueryDelay(e)}
            label="Брэнд"
            value={filters?.brandId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                brandId: e,
              });
            }}
            data={
              brandSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS.isEdit}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {
              setItemTypeSelectsFilterDelay({
                ...itemTypeFilterDelay,
                query: e,
              });
            }}
            label="Нэр төрөл"
            value={filters?.itemTypeId || ("" as string)}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                itemTypeId: e,
                classificationId: null,
                subClassificationId: null,
                categoryId: null,
                subCategoryId: null,
              });
              setClassificationFilter({
                ...classificationFilter,
                itemTypeId: e,
                query: null,
              });
              setSubClassificationFilter({
                ...subClassificationFilter,
                itemTypeId: e,
                query: null,
              });
              setCategoriesFilter({
                ...categoriesFilter,
                itemTypeId: e,
                query: null,
              });
              setSubCategoriesFilter({
                ...subCategoriesFilter,
                itemTypeId: e,
                query: null,
              });
            }}
            data={
              itemTypeSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS.isEdit}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {
              setClassificationQueryDelay(e);
            }}
            disabled={!classificationFilter.itemTypeId}
            label="Ангилал"
            value={filters?.classificationId || ""}
            onChange={(e: any) => {
              setSubClassificationFilter({
                ...subClassificationFilter,
                parentId: e,
              });
              setFilters({
                ...filters,
                classificationId: e,
                subClassificationId: null,
              });
            }}
            data={
              classificationSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS.isEdit}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {
              setSubClassificationQueryDelay(e);
            }}
            disabled={!subClassificationFilter.itemTypeId || !subClassificationFilter.parentId}
            label="Дэд ангилал"
            value={filters?.subClassificationId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                subClassificationId: e,
              });
            }}
            data={
              subClassificationSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS.isEdit}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => {
              setCategoriesQueryDelay(e);
            }}
            disabled={!categoriesFilter.itemTypeId}
            label="Категори"
            value={filters?.categoryId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                categoryId: e,
                subCategoryId: null,
              });
              setSubCategoriesFilter({
                ...subCategoriesFilter,
                parentId: e,
              });
            }}
            data={
              categoriesSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} hidden={!ERP_GDS.isEdit}>
          <SelectFilter
            iconWidth={170}
            onSearchChange={(e) => setSubCategoriesQueryDelay(e)}
            disabled={!subCategoriesFilter.itemTypeId || !subCategoriesFilter.parentId}
            label="Дэд категори"
            value={filters?.subCategoryId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                subCategoryId: e,
              });
            }}
            data={
              subCategoriesSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <ActionIcon
            onClick={() => {
              onClearFilter();
            }}
            size={36}
            color=""
            variant="light">
            <IconFilter />
          </ActionIcon>
        </Grid.Col>
      </Grid>
      <Space h={"xs"} />
      <Table name="stock.list" columns={columns} filters={filters} loadData={(data) => StockApi.list(data!)} />
    </>
  );
};

const useHeader = ({ stockStatus, variantPriceHistoryStatus }: { stockStatus: IReference[]; variantPriceHistoryStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_record, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => (
      <Avatar src={record?.image ?? ""} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => <Text size="sm">{record?.refCode}</Text>,
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => <Text size="sm">{record?.skuCode}</Text>,
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => (
      <Text size="sm" w="max-content">
        {`${record?.nameMon ?? "-"}${record?.optionValues?.length > 0 ? " - " + record?.optionValues?.map((ov: any) => ov.name) : ""}`}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Нийт үлдэгдэл тоо</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.totalQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: "Нөөцийн статус",
    sorter: true,
    dataIndex: "price",
    render: (record) =>
      record.stockStatus
        ? Tag(
            stockStatus.find((item: IReference) => item.code === record.stockStatus)?.color || "gray",
            stockStatus.find((item: IReference) => item.code === record.stockStatus)?.name || record.stockStatus,
          )
        : "-",
  },
  {
    title: <Text size="sm">Боломжит үлдэгдэл</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.availableQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Баталгаажилт хүлээж буй</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.reservedQuantity || "-"}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Борлуулалтад гарах</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.loadingQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Татан авах захиалгад</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.backorderQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Хүргэлтэнд яваа</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.onDeliveryQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: <Text size="sm">Хүргэлт хийсэн</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.deliveredQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: "Аюулгүйн нөөц - Min үлдэгдэл",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.noticeMinQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: "Аюулгүйн нөөц - Max үлдэгдэл",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.noticeMaxQuantity ?? "-"}
      </Text>
    ),
  },
  {
    title: "Дахин захиалах цэг",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.reOrderQuantityLevel ?? "-"}
      </Text>
    ),
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "price",
    render: (record) =>
      record.variantStatus
        ? Tag(
            variantPriceHistoryStatus.find((item: IReference) => item.code === record.variantStatus)?.color || "gray",
            variantPriceHistoryStatus.find((item: IReference) => item.code === record.variantStatus)?.name || record.stockStatus,
          )
        : "-",
  },
  {
    title: "Үндсэн нэгж",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.unit?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Агуулах",
    sorter: true,
    dataIndex: "price",
    render: (record) => {
      return (
        <Text w="max-content" size="sm">
          {record?.warehouse?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: <Text size="sm">Агуулахын үлдэгдэл</Text>,
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.warehouseQuantity ?? "-"}
      </Text>
    ),
  },
];
