import { Box, Button, Center, Checkbox, Flex, Modal, Pagination, Table, Text, TextInput } from "@mantine/core";
import { createStyles } from "@mantine/emotion";

interface TaxCategoryModalProps {
  opened: boolean;
  form: any;
  data: any[];
  filteredData: any[];
  pageSize: number;
  currentPage: number;
  selectedRow: any;
  searchQuery: string;
  handleModalClose: () => void;
  handleFieldChange: (e: any) => void;
  handleCheckBox: (id: string) => void;
  handlePagination: any;
  handleConfirm: () => void;
}

const TaxCategoryModal = (props: TaxCategoryModalProps) => {
  const { opened, form, data, filteredData, pageSize, currentPage, selectedRow, searchQuery } = props;
  const { handleModalClose, handleFieldChange, handleCheckBox, handlePagination, handleConfirm } = props;
  const { classes } = useStyles();
  return (
    <Modal
      opened={opened}
      onClose={handleModalClose}
      size="80%"
      centered
      title={form.values.vatType !== "STANDARD_RATED" ? "Татварын ангилалын код" : "Барааны ангилалын код"}
      padding="lg">
      <Box className={classes.wrapper}>
        <TextInput placeholder="Хайлт хийх" value={searchQuery} onChange={handleFieldChange} mb="sm" w="250px" />
        <Table withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Код</Table.Th>
              <Table.Th>Нэр</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {data.length > 0 ? (
            <Table.Tbody>
              {data.map((item: any) => (
                <Table.Tr key={item.id}>
                  <Table.Td width={30}>
                    <Checkbox checked={selectedRow === item.id} onChange={() => handleCheckBox(item.id)} />
                  </Table.Td>
                  <Table.Td width={70}>{item.code}</Table.Td>
                  <Table.Td>{item.name}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={3}>
                  <Center sx={(theme) => ({ padding: theme.spacing.xl })} className={classes.tableEmpty}>
                    <Text>Өгөгдөл олдсонгүй</Text>
                  </Center>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          )}
        </Table>

        <Flex justify="space-between" align="center" mt="md">
          <Pagination total={Math.ceil(filteredData.length / pageSize)} value={currentPage} onChange={handlePagination} />
          <Button onClick={handleConfirm} disabled={selectedRow === null} ml="auto">
            Болсон
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default TaxCategoryModal;

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: "860px",
  },
  tableEmpty: {
    minHeight: "61vh",
  },
}));
